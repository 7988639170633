<template>
  <div>
    <SlideShow :bannerList="bannerList"></SlideShow>
    <div class="searchBar">
      <Search
        type="2"
        :keys="getListParams.key"
        @searchKeyFun="searchKeyFun"
      ></Search>
    </div>
    <template>
      <div class="main_Div contBox">
        <MaterialClassifyMenu
          :keys="getListParams.key"
          @changeMenu="changeMenu"
          @selectSort="selectSort"
          @selectFreeFun="selectFreeFun"
          @selectReComFun="selectReComFun"
          @selectTimeFun="selectTimeFun"
          @searchKeyFun="searchKeyFun"
          @changeData="changeData"
        >
        </MaterialClassifyMenu>
        <div class="ListBox">
					<MaterialList ref="list" :materialList="materialListObj" :loading="loading" :listStatus="listStatus"
						@nextPage="nextPage"></MaterialList>
        </div>
      </div>
    </template>
  </div>
</template>
<script>
import SlideShow from "@/components/SlideShow";
import Search from "@/components/Search";
import MaterialList from "@/components/MaterialList";
import MaterialClassifyMenu from "@/components/MaterialClassifyMenu";
import { getBanner } from "../../Api";
import { mapGetters } from "vuex";

export default {
  name: "MaterialIndex",
  components: {
    SlideShow,
    Search,
    MaterialClassifyMenu,
    MaterialList,
  },
  data() {
    return {
      bannerList: [],
      loading: true,
			listStatus: {
				isData: true, // 判断是否有下一页
				isNull: false // 判断是否为空
			},
      getListParams: {
        id: 7,
        child: null,
        free: 0,
        page: 1,
        px: 0,
        reCom: 0,
        key: "",
        dataTime: null,
        per_page: 30,
      },
    };
  },
  created() {
    this.initData();
  },
  methods: {
    initData() {
      const params = {
        position: 1035,
        cate: 1051,
      };
      getBanner(params).then((res) => {
        this.bannerList = res.data;
      });
    },
    //修改请求数据
    changeData(data) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams = data;
    },
    //选择分类
    changeMenu(data) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.child = data;
    },
    //  选择排序
    selectSort(data) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.px = data;
    },
    //  选择筛选
    selectFreeFun(data) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.free = data;
    },
    //  选择推荐
    selectReComFun(data) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.reCom = data;
    },
    //  选择时间
    selectTimeFun(data) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.dataTime = data;
    },
		// 下一页
		nextPage() {
			this.getListParams.page += 1;
		},
    //  选定分页
    setPage(val) {
      this.getListParams.page = val;
      //回到顶部
      cancelAnimationFrame(this.timer);
      const self = this;
      self.timer = requestAnimationFrame(function fn() {
        const oTop =
          document.body.scrollTop || document.documentElement.scrollTop;
        if (oTop > 0) {
          document.body.scrollTop = document.documentElement.scrollTop =
            oTop - 100;
          self.timer = requestAnimationFrame(fn);
        } else {
          cancelAnimationFrame(self.timer);
        }
      });
    },
    //  搜索
    searchKeyFun(keys) {
			this.$refs.list && this.$refs.list.clear();
      this.getListParams.page = 1;
      this.getListParams.key = keys;
    },
  },
  computed: {
    ...mapGetters(["materialListObj"]),
  },
  watch: {
    getListParams: {
      handler: function () {
        this.getListParams.per_page = 30
				this.loading = true;
				(this.listStatus.isData = true), (this.listStatus.isNull = false);
				let params = this.$dealObjectValue(this.getListParams);
        this.$store
          .dispatch("app/getMaterialList", params)
          .then((res) => {
							this.loading = false;
							const {
								data
							} = res;
							if (data.total == 0) {
								this.$refs.list && this.$refs.list.clear();
								this.listStatus.isNull = true;
							} else if (data.current_page == data.last_page) {
								this.listStatus.isData = false;
							}
						})
						.catch((error) => {
							console.log(error);
							this.loading = false;
						});
      },
      deep: true,
    },
  },
};
</script>
<style scoped>
::v-deep .is-animated {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pageBox {
  width: 100%;
  text-align: center;
}

.searchBar {
  position: absolute;
  width: 100%;
  min-width: 1400px;
  top: 2rem;
  z-index: 8;
}

.contBox {
  position: relative;
  top: -0.66rem;
  z-index: 8;
}

.el-pagination.is-background .el-pager li:not(.disabled).active {
  background: #ff9e58;
  border-radius: 50%;
}
</style>